.pcoded .pcoded-header .navbar-logo,
.header-navbar .navbar-wrapper {
  background-color: transparent !important;
}

aside.pcoded-slider.ng-sidebar {
  top: 56px;
}

.slimscroll-wrapper,
.scroll-window {
  width: 100% !important;
}

.userlist-box {
  &.show {
    display: -webkit-box;
  }
  &.hide {
    display: none;
  }
}

.logo {
  width: 50px !important;
}

.menu-top-btn:hover span {
  color: #0073aa;
  cursor: pointer;
}

.menu-side-btn,
.menu-side-btn-active {
  text-decoration: none;
  color: #000;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  margin: auto;
}

.menu-side-btn span {
  margin: 10px;
  cursor: pointer;
}

.menu-side-btn-active span {
  margin: 10px;
  cursor: pointer;
}

.menu-side-btn:hover span {
  color: #0073aa;
}

.main-navbar-items {
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  flex-wrap: nowrap;
}

.menu-top-btn-active span {
  color: #0073aa;
  cursor: pointer;
  font-size: large;
}

.menu-top-btn span {
  cursor: pointer;
  font-size: large;
}

.menu-side-btn-active span {
  color: #0073aa;
}

.visible-navbar {
  position: absolute;
  z-index: 3;
  background-color: #fff;
  margin-top: 15px;
  height: 100vh;
  position: fixed;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.hidden-navbar {
  display: none;
}

.main-navbar-items {
  margin-top: 15px;
}

.modal-footer {
  display: flex;
  flex-direction: row;
}

.superior-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #000;
  color: #fff;
}

.client-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.right-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* Menu notificaciones */

.notification-wrapper {
  position: relative;
  padding: 8px 15px;
  float: right;
  border-radius: 5px;
}

.notification-wrapper .notification-btn {
  color: #ffffff;
  cursor: pointer;
}

.notification-wrapper .notification-btn:hover {
  color: #0073aa;
}

.notification-wrapper .notification-content p {
  color: #000;
  text-align: center;
}

.notification-wrapper .notification-content {
  position: absolute;
  top: 55px;
  right: 5px;
  max-height: 40vh;
  overflow-y: auto;
  width: 350px;
  background-color: #c5c5c5;
  z-index: 300;
  border-bottom: solid 3px #c5c5c5;
}

.notification-wrapper .notification-header {
  position: sticky;
  top: 0;
  padding: 10px;
  color: #ffffff;
  background-color: #0073aa;
  border: solid 2px #0073aa;
  cursor: pointer;
}

.notification-wrapper .notification-header .label {
  display: inline-block;
  width: 90%;
  text-align: center;
  font-weight: 500;
}

.notification-header:hover {
  background-color: #fff;
}

.notification-header:hover .label p {
  color: #0073aa !important;
}

.notification-header span p {
  margin-top: 10px;
}

.notification-card {
  background-color: #fff;
  color: #000;
  text-align: justify;
  margin: 5px;
  cursor: pointer;
  border-radius: 0.5rem;
  padding: 10px;
  border: solid 2px #fff;
}

.notification-card:hover {
  border: solid 2px #0073aa;
}

.btn-log-out-header {
  color: #fff;
  text-decoration: none;
}

.link:hover {
  color: #0073aa;
  cursor: pointer;
}
